/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import { visuallyHidden } from "@mui/utils";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import axios from "axios";
import Spinner from "react-spinner-material";
import ViewSecret from "./ViewSecret";
import Deactivate from "./Deactive";
import { Close } from "./CloseContext";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "api", numeric: false, label: "Api" },
  { id: "description", numeric: true, label: "Description" },
  { id: "client_id", numeric: true, label: "client_id" },
  { id: "action", numeric: true, label: "Action" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function DatabaseTable() {
  const access = sessionStorage.getItem("secret_key_admin")
  // eslint-disable-next-line
  const [close, setClose] = useContext(Close);
  // eslint-disable-next-line
  const [idx, setIdx] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // eslint-disable-next-line
  const [showModal, setShowModal] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  // eslint-disable-next-line

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [anchor, setAnchor] = React.useState(null);
  // eslint-disable-next-line
  const [anchor2, setAnchor2] = React.useState(null);

  const openMenu = (event, elem) => {
    setIdx(elem);
    setShowModal(false);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const getDate = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_URL}/api/idp/v2/secret/id-secret`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("An Error occurred!");
        setError(true);
        setLoading(false);
      });
  };
  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/api/idp/v2/secret/id-secret`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getData();
  }, [close]);
  React.useEffect(() => {
    getDate();
  }, []);
  return (
    <div className="container py-3">
      {loading ? (
        <div
          style={{
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
        </div>
      ) : (
        <Box sx={{ width: "100%" }}>
          {error && (
            <Alert variant="outlined" severity="error">
              An error occurred
            </Alert>
          )}
          {!data.length && (
            <Alert variant="outlined" severity="warning">
              No Databases added yet
            </Alert>
          )}
          {data.length > 0 && (
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750, px: 2 }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell align="left">{row.api}</TableCell>
                            <TableCell align="left">
                              {row.description}
                            </TableCell>
                            <TableCell align="left">{row.client_id}</TableCell>
                            <TableCell align="left">
                              <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Actions">
                                  <IconButton
                                    onClick={(e, elem = row) =>
                                      openMenu(e, elem)
                                    }
                                    sx={{ p: 0 }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>
                                <Menu
                                  sx={{ mt: "23px" }}
                                  id="menu-appbar"
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  anchorEl={anchor}
                                  open={Boolean(anchor)}
                                  onClose={closeMenu}
                                >
                                  {access && <MenuItem
                                    className="m__item"
                                    onClick={(e) => setAnchor2(e.currentTarget)}
                                  >
                                    <Deactivate id={idx.id} />
                                  </MenuItem>}
                                  <MenuItem
                                    className="m__item"
                                    onClick={(e) => setAnchor2(e.currentTarget)}
                                  >
                                    <ViewSecret id={idx.id} />
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </Box>
      )}
    </div>
  );
}
