import React,{useContext} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { toast } from "react-toastify";
import { Close } from "./CloseContext";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({ id }) {
  const [close,setClose] = useContext(Close)
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Deactivate = async () => {
    await axios
      .delete(`${process.env.REACT_APP_URL}/api/idp/v2/db/${id}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success("Successfully deactivated this database");
        handleClose();
        setClose((prev)=>!prev)
      })
      .catch((err) => {
        toast.error("An error occurred");
      });
  };

  return (
    <div>
      <Typography onClick={handleClickOpen}>Deactivate database</Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Deactivate database
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this database ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="left_right">
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={Deactivate}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
