import React from 'react'

const Error = ({msg}) => {
  return (
    <div className='center my-5 mx-2'>
      <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        { msg ? <span className="font-medium">{msg}</span> : <span className="font-medium">An error occurred!</span> }
      </div>
    </div>
  )
}

export default Error