import React from "react";
import Api from "../components/Apis/Index";

const Apis = () => {
  return (
    <div className="container mx-auto">
      <Api />
    </div>
  );
};

export default Apis;
