import React from "react";
import DatabaseTable from "./DatabaseTable";
import DatabaseModal from "./DatabaseModal";
import MessageProvider from "./Context";
import CloseProvider from "./CloseContext";
import './style.css'

const Index = () => {
  const access = sessionStorage.getItem("secret_key_admin")
  return (
    <div className="my-8">
      <CloseProvider>
        <MessageProvider>
          {access && <DatabaseModal />}
          <DatabaseTable />
        </MessageProvider>
      </CloseProvider>
    </div>
  );
};

export default Index;
