import React from 'react'
import ApisTable from './ApisTable'
import ApisModal from './ApisModal'
import MessageProvider from './Context'

const Index = () => {
  return (
    <div className='my-8'>
      <MessageProvider>
        <ApisModal/>
        <ApisTable/>
      </MessageProvider>
    </div>
  )
}

export default Index;