import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import logo from "../assets/favicon.ico";

const navigation = [
  { name: "Projects", to: "/projects", current: true },
  { name: "Applications", to: "/applications", current: false },
  { name: "APIs", to: "/apis", current: false },
];
// if (sessionStorage.getItem("secret_key")) {
//   navigation.push(
//     { name: "Secret keys", to: "/secret-keys", current: false },
//     { name: "Databases", to: "/databases", current: false }
//   );
// }

const navigation2 = [
  { name: "Secret keys", to: "/secret-keys", current: false },
  { name: "Databases", to: "/databases", current: false },
];
export default function Example() {
  const navigate = useNavigate();
  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="min-h-full header">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-14">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.to}
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                          >
                            {item.name}
                          </NavLink>
                        ))}
                        {sessionStorage.getItem("secret_key") &&
                          navigation2.map((item) => {
                            return (
                              <NavLink
                                key={item.name}
                                to={item.to}
                                className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                              >
                                {item.name}
                              </NavLink>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {sessionStorage.getItem("role") === "4" && (
                        <button
                          type="button"
                          className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <NavLink to="/settings" id="test">
                            <i class="fa-solid fa-gear"></i>
                          </NavLink>
                        </button>
                      )}

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <Avatar sx={{ width: 24, height: 24 }} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              <NavLink
                                to="/sign-out"
                                onClick={logout}
                                className="block px-4 py-2 text-sm text-gray-700"
                              >
                                Log Out
                              </NavLink>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.to}
                      className="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                    >
                      {item.name}
                    </NavLink>
                  ))}
                  {sessionStorage.getItem("secret_key") &&
                    navigation2.map((item) => {
                      return (
                        <NavLink
                          key={item.name}
                          to={item.to}
                          className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                        >
                          {item.name}
                        </NavLink>
                      );
                    })}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <Avatar sx={{ width: 24, height: 24 }} />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {sessionStorage.getItem("name")}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {sessionStorage.getItem("email")}
                      </div>
                    </div>
                    {sessionStorage.getItem("role") === "4" && (
                      <button
                        type="button"
                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <NavLink to="/settings" id="test">
                          <i class="fa-solid fa-gear"></i>
                        </NavLink>
                      </button>
                    )}
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    <NavLink
                      to="sign-out"
                      onClick={logout}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Log Out
                    </NavLink>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
