/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import Paper from '@mui/material/Paper';
import { Message } from "./Context";
import axios from 'axios';
import Error from '../Error';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, label: 'Id' },
  { id: 'name', numeric: true, label: 'Name' },
  { id: 'repository', numeric: true, label: 'Repository' },
  { id: 'project', numeric: true, label: 'Project' },
  { id: 'language', numeric: true, label: 'Language' },
  { id: 'description', numeric: true, label: 'Description' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align='left' sortDirection={orderBy === headCell.id ? order : false} >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ApisTable() {
  
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [message] = React.useContext(Message);
  const [order, setOrder] = React.useState('asc');
  const [loading, setLoading] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderBy, setOrderBy] = React.useState('calories');

  const getDate = async () => {
    setLoading(true); setErrorMsg(false);
    await axios.get(`${process.env.REACT_APP_URL}/api/idp/v2/api`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(true);
        setLoading(false);
      });
  };

  const getData = async () => {
    setErrorMsg(false);
    await axios.get(`${process.env.REACT_APP_URL}/api/idp/v2/api`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(true);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDetails = (id) => {
    navigate(`/apis/${id}/details`);
  };

  React.useEffect(() => {
    getData();
  }, [message]);
  React.useEffect(() => {
    getDate();
  }, []);

  return (
    <div className="container py-3">
      {
        loading ? <div className='text-center my-14 py-5'><CircularProgress size="3.5rem"/></div> :
        errorMsg ? <Error msg="An error occurred while getting APIs!"/> :
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size='medium'>
                <EnhancedTableHead
                  order={order} orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.repository}</TableCell>
                        <TableCell align="left">{row.language}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">
                          <Button size="small" onClick={(e) => onDetails(row.id)}>
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]} component="div"
              count={data.length} rowsPerPage={rowsPerPage}
              page={page} onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      }
    </div>
  );
}