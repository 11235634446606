/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import FilesTable from './FilesTable'
import { CircularProgress } from '@mui/material'
import Error from '../../../Error'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import FilesModal from './FilesModal'

const Files = ({id}) => {

  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(false)
  const [data, setData] = React.useState([]);

  const getData = async () => {
    setErrorMsg(false);
    await axios.get(`${process.env.REACT_APP_URL}/api/idp/v2/app/${id}/pc`, {
        headers: { Authorization: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(true);
      });
  };

  useEffect(()=> {
    const response = async() => {
      setLoading(true);
      await getData();
      setLoading(false);
    }
    response()
  }, [])

  return (
    <div className='w-full py-3'>
      <div className='flex justify-between'>
        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>Postman Collection</h5>
        <FilesModal id={id} get={getData}/>
      </div>

      <div className='my-7'>
        {
          loading ? <div className='text-center my-14 py-5'><CircularProgress size="3.5rem"/></div> :
          errorMsg ? <Error msg="An error occurred while getting APIs!"/> : <FilesTable id={id} get={getData} data={data}/>
        }
      </div>
    </div>
  )
}

export default Files