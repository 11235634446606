import React from 'react'
import SecretKeyTable from './SecretKeyTable'
import SecretKeyModal from './SecretKeyModal'
import CloseProvider from './CloseContext'

const Index = () => {
  const access = sessionStorage.getItem("secret_key_admin")
  return (
    <div className='my-8'>
      <CloseProvider>
      {access &&  <SecretKeyModal/>}
      <SecretKeyTable/>
      </CloseProvider>
    </div>
  )
}

export default Index;