import { createContext, useState } from "react";

export const Close = createContext();

const CloseProvider = ({ children }) => {
  const [close, setClose] = useState(false);
  return <Close.Provider value={[close, setClose]}>{children}</Close.Provider>;
};

export default CloseProvider;
