/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useRef } from 'react'
import { CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown'
import { toast } from 'react-toastify';
import Error from '../../Error';
import axios from 'axios';

const Readme = ({id}) => {

  const inputRef = useRef()
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [disable, setDisable] = useState(true)
  const [content, setContent] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)

  const getFile = () => {
    setLoading(true); setFile(null); setErrorMsg(false)
    axios(`http://192.168.100.7:9800/api/idp/v2/api/${id}/readme`, {
      headers: { 
        Authorization: sessionStorage.getItem("token"),
        responseType: 'text'
      },
    })
    .then(res => {
      if(!res.data) setContent(true)
      setFile(res.data); setLoading(false)
    })
    .catch(err => {setLoading(false); setErrorMsg(true)})
  };

  const handleCreate = () => {
    setContent(false);
    setDisable(false);
    setTimeout(() => {
      inputRef.current.focus();
    });
  }

  const onSubmit = () => {
    setDisable(true); setLoadingBtn(true)
    axios.post(`http://192.168.100.7:9800/api/idp/v2/api/${id}/readme`, {text: file}, {
      headers: { Authorization: sessionStorage.getItem("token") },
    })
    .then(res => { toast.success("Successfully saved!"); getFile(); setLoadingBtn(false) })
    .catch(err => {toast.error("An error occurred!"); setDisable(false); setLoadingBtn(false)})
    setDisable(true)
  }

  useEffect(()=>{
    getFile()
  }, [])

  return (
    <div>
      <div className="inline-flex rounded-md shadow-sm" role="group">
        <button onClick={()=>handleCreate()} style={(file || !content) ? {background: "#ddd"} : {}} disabled={(file || !content)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          <i className="fa-solid fa-folder-plus mr-2"></i>
          Create
        </button>
        <button style={(!disable || !file) ? {background: "#ddd"} : {}} disabled={!disable || !file} onClick={()=>setDisable(false)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          <i className="fa-solid fa-pen-to-square mr-2"></i>
          Edit
        </button>
        <button style={(disable || !file) ? {background: "#ddd"} : {}} disabled={(disable || !file)} onClick={()=>onSubmit()} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          { loadingBtn ? <CircularProgress size="0.9rem" className='mr-2'/> : <i className="fa-solid fa-floppy-disk mr-2"></i> }
          Save
        </button>
      </div>

      {
        loading ? <div className='my-14 py-10 text-center'><CircularProgress/></div> :
        errorMsg ? <Error msg="An error occurred white getting Readme file"/> :
        content ?
          <div className='text-center my-14 py-14'>
            <i style={{fontSize: "70px"}} className="fa-regular fa-folder-open"></i>
            <p className='my-5 text-xl'>No data</p>
          </div> :
        <>
          <div className='readme w-100 mt-5 mb-4 border rounded-md'>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className='lg:border-r'>
                <div className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm rounded-t-lg w-full p-2.5">
                  <h4 style={{fontSize: "20px"}}>Edit</h4>
                </div>
                <textarea ref={inputRef} disabled={disable} cols={50} className='w-full h-full p-5 my-0' onChange={(e)=> setFile(e.target.value)} value={file} onResize={false}></textarea>
              </div>
              <div>
                <div className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm rounded-t-lg w-full p-2.5">
                  <h4 style={{fontSize: "20px"}}>Review</h4>
                </div>
                <ReactMarkdown className='p-5 h-full'>{file}</ReactMarkdown>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Readme