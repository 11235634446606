import React from 'react'
import MessageProvider from "./Context";
import ApplicationTable from './ApplicationTable'
import ApplicationModal from './ApplicationModal'

const Index = () => {
  return (
    <div className='my-8'>
      <MessageProvider>
        <ApplicationModal/>
        <ApplicationTable/>
      </MessageProvider>
    </div>
  )
}

export default Index