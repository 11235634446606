import Apis from "./pages/Apis";
import { Route, Routes, useNavigate, Navigate,useSearchParams } from "react-router-dom";
import Projects from "./pages/Projects";
import Applications from "./pages/Applications";
import SecretKeys from "./pages/SecretKeys";
import Databases from "./pages/Databases";
import Settings from "./pages/Settings";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Login from "./components/Login";
import Tabs from "./components/Project/Tabs";
import ApisTabs from "./components/Apis/Tabs/Tabs";
import AppTabs from "./components/Applications/Tabs/Tabs";
import { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";

function App() {
  const token = sessionStorage.getItem("token");
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect_to");
  const secret_key = sessionStorage.getItem("secret_key");
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  useEffect(() => {
    if(redirect) sessionStorage.setItem("redirect", redirect)
    navigate(`${window.location.pathname}`);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="App">
      {token ? (
        <>
          <Header />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Navigate to="/projects" />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id/details" element={<Tabs />} />
            <Route path="/apis" element={<Apis />} />
            <Route path="/apis/:id/details" element={<ApisTabs />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/applications/:id/details" element={<AppTabs />} />
            {secret_key && (
              <Route path="/secret-keys" element={<SecretKeys />} />
            )}
            {secret_key && <Route path="/databases" element={<Databases />} />}
            {parseInt(role) === 4 && (
              <Route path="/settings" element={<Settings />} />
            )}
          </Routes>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
