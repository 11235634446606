import React from "react";
import Setting from "../components/Settings/Index";

const Settings = () => {
  return (
    <div className="container mx-auto">
      <Setting />
    </div>
  );
};

export default Settings;
